<template>
  <a-spin :spinning="ifSub">
    <a-form :form="form">
      <a-form-item label="巡检点类型" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-select style="width: 100%;" placeholder="请选择" v-decorator="['type',{initialValue:'monitoring_point'}]" @change="selectType">
          <a-select-option
            v-for="item in vindicateTypeSelectList"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="巡检点名称" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="formItem.type === 'other'"
                 v-decorator="['name', { rules: [{ required: true, message: '请选择或填写名称' }] }]"
                 placeholder="请填写名称"
        >
        </a-input>
        <a-input-search v-if="formItem.type === 'monitoring_point'"
                        placeholder="请选择监测点"
                        v-decorator="['name', { rules: [{ required: true, message: '请选择或填写名称' }] }]"
                        @search="clickMonitoringPoint">
          <a-button type="primary" slot="enterButton">
            <a-icon type="plus"/>
          </a-button>
        </a-input-search>
      </a-form-item>
      <a-form-item label="所属维护部门" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-tree-select
          placeholder="请选择维护部门"
          style="width: 100%;"
          :treeData="deptList"
          v-decorator="['department.id', { rules: [{ required: true, message: '请选择部门' }] }]"
        >
        </a-tree-select>
      </a-form-item>
      <a-form-item label="所在位置" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-tree-select
          :disabled="formItem.type !== 'other' ? true : false"
          placeholder="请选择所在位置"
          style="width: 120px;"
          :treeData="areaList"
          v-decorator="['area.id']"
        >
        </a-tree-select>
        <a-input
          :disabled="formItem.type !== 'other' ? true : false"
          style="width: 231px; margin-left: 2px;"
          v-decorator="['location']"
          placeholder="请写具体位置"
        >
        </a-input>
      </a-form-item>
      <a-form-item label="标签" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input
          v-decorator="['tag']"
          placeholder="请填写标签"
        >
        </a-input>
      </a-form-item>
    </a-form>
    <a-modal
      :footer="null"
      :destroyOnClose="true"
      title="监测点"
      :visible="visible"
      @cancel="handCancel"
    >
      <monitoringPointTree @selectedTreeNode="selectedTreeNode" @selectTreeNodeDetail="selectTreeNodeDetail"></monitoringPointTree>
    </a-modal>
  </a-spin>
</template>

<script>
  import { basicInfoFormVO } from './common/basicInfoFormVo'
  import { vindicateTypeSelect } from './common/common'
  import SERVICE_URLS from '@/api/service.url'
  import entityCRUDCallback from '../../common/mixins/entityCURDCallback'
  import monitoringPointTree from '@/components/monitoringPointTree/List'

  export default {
    name: 'Form',
    components: { monitoringPointTree },
    mixins: [entityCRUDCallback],
    props: {
      type: {
        type: String,
        default: 'add'
      }
    },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.xj.xjNode,
        deviceList: [],
        labelCol: { span: 5 },
        wrapperCol: { span: 17 },
        initFormItem: basicInfoFormVO(),
        vindicateTypeSelectList: vindicateTypeSelect(),
        deptList: [],
        areaList: [],
        facilityList: [],
        showFormItem: false,
        nodeId: '',
        selectData: null,
        visible: false
      }
    },
    created () {
      this.loadDept()
      this.loadAreaList()
      this.getFacilityList()
    },
    watch: {
      selectData (val) {
        this.selectData = val
      }
    },
    methods: {
      getFacilityList () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.facilityApi.selectFacility,
          noTips: true,
          data: { departmentId: '' },
          success: (data) => {
            this.facilityList = data.body
          }
        })
      },
      loadDept () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.deptList = data.body
          }
        })
      },
      loadAreaList () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentInfoApi.areaTree,
          noTips: true,
          success: (data) => {
            this.areaList = data.body
          }
        })
      },
      selectType (value) {
        this.formItem.type = value
        this.form.resetFields()
      },
      setVOFields (values) {
        Object.assign(this.formItem, values)
        if (!values.area.id) {
          this.formItem.area = null
        }
        if (this.formItem.type === 'other') {
          this.formItem.instanceId = ''
        } else {
          this.formItem.instanceId = this.selectData.id
        }
      },
      setFields (values) {
        Object.assign(this.formItem, values)
        this.formItem.type = this.formItem.node.type
        this.selectData = {
          id: this.formItem.node.instanceId
        }
        this.form.setFieldsValue({
          type: this.formItem.node.type,
          'area.id': this.formItem.node.area ? this.formItem.node.area.id : null,
          'department.id': this.formItem.node.department.id,
          location: this.formItem.node.location,
          tag: this.formItem.node.tag,
          name: this.formItem.node.name
        })
      },
      clickMonitoringPoint () {
        this.visible = true
      },
      handCancel () {
        this.visible = false
      },
      selectedTreeNode (key, node) {

      },
      selectTreeNodeDetail (detail) {
        if (detail.type === 'OTHER') {
          this.$message.warning('选中的节点为其他类型，请选择设备/设施')
          return
        }
        const list = {
          area: {
            id: detail.areaId
          },
          department: {
            id: detail.orgId
          },
          id: detail.id,
          location: detail.location,
          name: detail.name,
          tag: detail.tag
        }
        this.selectData = list

        this.form.setFieldsValue({
          'name': this.selectData.name,
          'area.id': this.selectData.area.id,
          'department.id': this.selectData.department.id,
          location: this.selectData.location,
          tag: this.selectData.tag
        })
        this.visible = false
      }
    }
  }
</script>

<style scoped>
  .text-title {
    font-size: 17px;
    font-weight: 700;
    font-family: 'Lantinghei SC', 'Open Sans', Arial, 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', 'STHeiti',
    'WenQuanYi Micro Hei', SimSun, sans-serif;
  }
</style>
