const questionFormVO = () => {
  return {
    id: null,
    name: '',
    sorter: '',
    type: '',
    defaultValue: '',
    probablyOption: '',
    actionDescribe: '',
    maxValue: '',
    minValue: '',
    node: {
      id: null
    },
  }
}

export default questionFormVO