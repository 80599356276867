<template>
  <div>
    <a-button style="margin-bottom: 15px;" icon="plus" @click="handleAdd" type="primary">新增</a-button>
    <a-table
      size="small"
      bordered
      :rowKey="(record) => record.id"
      @change="tableChange"
      :pagination="pagination"
      :columns="columns"
      :dataSource="tableData"
      :loading="tableLoading"
    >
      <template slot="_index" slot-scope="text, record, index">{{ index + 1 }}</template>
      <template slot="type" slot-scope="text, record">
        <span v-if="record.type === 'STATE'">状态型</span>
        <span v-if="record.type === 'NUMBER'">数值型</span>
        <span v-if="record.type === 'TEXT'">文本型</span>
      </template>
      <template slot="probablyOption" slot-scope="text, record" style="text-align: left;">
        <a-tag
          color="cyan"
          style="margin: 3px;"
          v-for="(item, index) in JSON.parse(record.probablyOption)"
          :key="index"
        >
          {{ index + 1 }}.{{ item }}
        </a-tag>
        <span v-if="!JSON.parse(record.probablyOption).length" style="color: #c5c8ce;">-</span>
      </template>
      <template slot="minValue" slot-scope="text, record">
        <span v-if="record.minValue !== null">{{ record.minValue }}</span>
        <span v-else style="color: #c5c8ce;">-</span>
      </template>
      <template slot="maxValue" slot-scope="text, record">
        <span v-if="record.maxValue !== null">{{ record.maxValue }}</span>
        <span v-else style="color: #c5c8ce;">-</span>
      </template>
      <template slot="action" slot-scope="text, record, index">
        <div style="width: 150px; margin: auto;">
          <a-button
            shape="circle"
            icon="up"
            :disabled="index === 0"
            @click.stop="handleQuestionOrderChange(record, index, 'up')"
            size="small"
          />
          <a-divider type="vertical" />
          <a-button
            shape="circle"
            icon="down"
            :disabled="index === tableData.length - 1"
            @click.stop="handleQuestionOrderChange(record, index, 'down')"
            size="small"
          />
          <a-divider type="vertical" />
          <a-button shape="circle" icon="edit" @click="handleEdit(record.id)" size="small" />
          <a-divider type="vertical" />
          <a-button shape="circle" type="danger" icon="delete" @click="handleDelete(record.id)" size="small" />
        </div>
      </template>
    </a-table>
    <!--巡检项目-->
    <a-modal title="巡检项目添加" v-model="addModal" width="650px">
      <question-form
        type="question"
        ref="addRef"
        :nodeId="searchParams.nodeId"
        @addSuccess="addSuccess"
      ></question-form>
      <template slot="footer">
        <a-button :loading="addLoading" @click="handleAddOkWithContinue" type="primary" style="margin-right: 5px;"
          >保存新增
        </a-button>
        <a-button :loading="addLoading" @click="handleAddOkWithCopy" type="primary" style="margin-right: 5px;"
          >保存复制
        </a-button>
        <a-button :loading="addLoading" @click="handleAddOk" type="primary" style="margin-right: 5px;"
          >保存关闭</a-button
        >
        <a-button @click="closeAddModal">取消</a-button>
      </template>
    </a-modal>
    <a-modal width="650px" title="巡检项目修改" :visible="editModal" @ok="handleEditOk" @cancel="closeEditModal">
      <question-form
        ref="editRef"
        type="edit"
        :nodeId="searchParams.nodeId"
        @editSuccess="editSuccess"
        @editError="editError"
      ></question-form>
    </a-modal>
  </div>
</template>

<script>
import entityCRUDWithCopy from '../../common/mixins/entityCRUDWithCopy'
import SERVICE_URLS from '@/api/service.url'
import { questionColumns } from './common/common'
import questionForm from './QuestionForm'
export default {
  name: 'List',
  mixins: [entityCRUDWithCopy],
  components: { questionForm },
  props: {
    type: {
      type: String,
      default: 'add',
    },
  },
  data() {
    return {
      entityBaseUrl: SERVICE_URLS.xj.xjItem,
      initColumns: questionColumns(),
      searchParams: {
        number: '0',
        size: '10',
        direction: 'ASC',
        order: 'sorter',
        nodeId: '',
      },
      orderChangeQuestionOkButtonLoading: false,
      addQuestionOkButtonLoading: false,
      questionAddModal: false,
      editQuestionOkButtonLoading: false,
    }
  },
  methods: {
    getList(id) {
      this.searchParams.nodeId = id
      this.search()
    },
    //巡检项目添加等操作
    handleQuestionAdd() {
      this.questionAddModal = true
      this.$nextTick(() => {
        this.$refs.questionAddRef.formItem.device.id = this.tempDevice.id
      })
    },
    handleQuestionAddOk() {
      this.$refs.questionAddRef.handleAddSubmit()
      this.addQuestionOkButtonLoading = true
    },
    handleQuestionAddError(errorType) {
      if (errorType === 'httpError') {
        this.questionAddModal = false
        this.addQuestionOkButtonLoading = false
      } else if (errorType === 'formError') {
        this.$nextTick(() => {
          this.addQuestionOkButtonLoading = false
        })
      }
    },
    handleQuestionOrderChange(record, index, type) {
      const sortList = []
      if (type === 'up') {
        const selectSortData = {
          id: record.id,
          sorter: this.tableData[index - 1].sorter,
        }
        sortList.push(selectSortData)
        const upRowSortData = {
          id: this.tableData[index - 1].id,
          sorter: record.sorter,
        }
        sortList.push(upRowSortData)
      } else {
        const selectSortData = {
          id: record.id,
          sorter: this.tableData[index + 1].sorter,
        }
        sortList.push(selectSortData)
        const upRowSortData = {
          id: this.tableData[index + 1].id,
          sorter: record.sorter,
        }
        sortList.push(upRowSortData)
      }
      this.$http(this, {
        url: SERVICE_URLS.xj.xjItem.updateSort,
        data: sortList,
        noTips: true,
        success: () => {
          this.search()
        },
      })
    },
  },
}
</script>

<style scoped>
.text-title {
  font-size: 17px;
  font-weight: 700;
  font-family: 'Lantinghei SC', 'Open Sans', Arial, 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', 'STHeiti',
    'WenQuanYi Micro Hei', SimSun, sans-serif;
}
</style>
